<template>
  <div>
    <b-row>
      <b-col>
        <h1>Livestreams</h1>
      </b-col>
      <b-col cols="auto">
        <b-button class="mb-5 add-button" @click="addLivestream()">
          Add
        </b-button>
      </b-col>
    </b-row>
    <b-overlay :show="isLoading">
      <vuetable
        ref="livestreamtable"
        :api-mode="false"
        :fields="fields"
        :data="livestreams"
      >
        <template slot="active" scope="props">
          <b-form-checkbox
            v-model="livestreams[props.rowIndex].active"
            name="active"
            class="content-switch"
            disabled
          ></b-form-checkbox>
        </template>
        <template slot="actions" scope="props">
          <font-awesome-icon
            icon="edit"
            class="action-icon"
            data-toggle="tooltip"
            data-placement="top"
            title="Edit item"
            @click="editLivestream(props.rowIndex)"
            ref="btnEdit"
          ></font-awesome-icon>
          <font-awesome-icon
            icon="trash"
            class="ml-2 action-icon"
            data-toggle="tooltip"
            data-placement="top"
            title="Remove item"
            @click="deleteLivestream(props.rowData, props.rowIndex)"
          ></font-awesome-icon>
        </template>
      </vuetable>
    </b-overlay>
  </div>
</template>
<script>
import Vuetable from "vuetable-2";

export default {
  components: {
    Vuetable,
  },
  data() {
    return {
      isLoading: false,
      fields: [
        {name: "active", sortField: 'Active'},  
        {name: "id", sortField: 'Id'},
        {name: `title.${this.$root.$i18n.locale}`, title: 'Title', sortField: 'Title'}, 
        {name: `type.${this.$root.$i18n.locale}`, title: 'Type', sortField: 'Type'},  
        {name: "lang", sortField: 'Language'},
        {name: "actions", sortField: 'Actions'}
      ]
    };
  },
  async created() {
    this.doFetchAll();
  },
  methods: {
    async doFetchAll() {
      this.isLoading = true;
      await this.$store.dispatch("Livestreams/fetchAll");
      this.isLoading = false;
    },
    addLivestream() {
      this.$router.push({ path: "livestream-detail" });
    },
    editLivestream(index) {
      this.$router.push({
        path: "livestream-detail",
        query: { id: index },
      });
    },
    async deleteLivestream(livestream, index) {
      this.isLoading = true;
      const isConfirmed = await this.$bvModal.msgBoxConfirm(
        `Are you sure you want to remove ${livestream.id}?`,
        {
          title: "Confirm delete",
          okVariant: "danger",
          centered: true,
        }
      );
      if (isConfirmed) {
        this.livestreams.splice(index, 1);
        await this.$store.dispatch("Livestreams/update", this.livestreams);
      }
      this.isLoading = false;
    },
  },
  computed: {
    livestreams() {
      return this.$store.state.Livestreams.livestreams;
    },
  },
};
</script>
<style lang="scss">
$color-primary: var(--primary);
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);
$background-image: var(--theme-background-image);

.action-icon:hover {
  color: $color-secondary;
  cursor: pointer;
}
</style>
